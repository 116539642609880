<template>
  <div class="ai-data">
    <div class="total block">
      <div class="rmb">
        <div class="label">今日成交订单金额（元）</div>
        <div class="value">{{ formatMoney(total.total_amount || 0) }}</div>
      </div>
      <div class="stats">
        <div class="stat">
          <div class="label">今日成交订单</div>
          <div class="value">{{ formatMoney(total.total_order || 0) }}</div>
        </div>

        <div class="stat">
          <div class="label">今日累计看播量</div>
          <div class="value">{{ formatMoney(total.total_online || 0) }}</div>
        </div>

        <div class="stat">
          <div class="label">今日累计直播时长</div>
          <div class="value">{{ formatMoney(total.live_time || 0) }}h</div>
        </div>

      </div>
    </div>
    <div class="list-wrap" v-if="list.length">
      <div class="head">
        <h2 class="title">直播排行榜单（TOP 10）</h2>
        <van-button type="default" size="small" @click="loadData"> 刷新 </van-button>
      </div>
      <!-- <h2 style="padding-left: 15px;font-size: 14px;font-weight: bold;color: #333;"></h2> -->
      <div class="list block" v-for="(item, index) in list" :key="index">
        <div class="user">
          <img :src="item.avatar" class="avatar">
          <div class="name">{{ item.acc_name }}</div>
          <div class="living">{{ item.is_live == 1 ? '直播中' : '' }}</div>
        </div>
        <div class="stats">
          <div class="stat">
            <div class="label">今日成交GMV</div>
            <div class="value">{{ formatMoney(item.today_order_amount || 0) }}</div>
          </div>

          <div class="stat">
            <div class="label">今日在线人数</div>
            <div class="value">{{ formatMoney(item.today_online || 0) }}</div>
          </div>

          <div class="stat">
            <div class="label">累计直播时长</div>
            <div class="value">{{ formatMoney(item.live_time || 0) }}h</div>
          </div>

          <div class="stat">
            <div class="label">累计在线人数</div>
            <div class="value">{{ formatMoney(item.live_num || 0) }}</div>
          </div>

          <div class="stat">
            <div class="label">累计成交GMV</div>
            <div class="value">{{ formatMoney(item.total_order_amount || 0) }}</div>
          </div>

          <div class="stat">
            <div class="label">累计成交订单</div>
            <div class="value">{{ formatMoney(item.order_num || 0) }}</div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast, Button } from "vant";

Vue.use(Toast);
Vue.use(Button);

export default {
  name: "ai-data",
  data() {
    return {
      total: {},
      list: []
    };
  },
  created() {
    document.title = "AI智播 - 数据大屏展示";
  },
  mounted() {
    this.loadData();
  },
  methods: {
    formatMoney(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });
      return formatter.format(value).replace('$', '').replace('.00', '');
      // return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
    },
    loadData() {
      Toast.loading({
        message: '加载中...',
        forbidClick: false,
      });

      this.$get('app/ai_data', null, (res) => {
        // console.log(res);
        Toast.clear();
        if (res.code == 0) {
          this.total = res.data.total || {};
          this.list = res.data.list || [];
        }
      });
    },

  }
};
</script>

<style lang="scss" scoped>
.ai-data {
  background-color: #f7f8f2;
}
.block {
  margin: 15px;
  background-color: rgb(241,83,118);
  border-radius: 6px;
  padding: 15px;
  font-size: 14px;

}

.rmb {
  text-align: center;
  margin-bottom: 15px;

  .label {
    font-size: 14px;
    color: #fff;

  }

  .value {
    font-size: 24px;
    color: #fff;
    margin-top: 10px;
    font-family: 'PingFang SC';
  }
}

.total {
  .stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-size: 12px;

    .value {
      font-size: 16px;
      margin-top: 5px;
    }

    .stat {
      text-align: center;
    }
  }
}

.list {
  background-color: rgba(0, 0, 0, 0.73);
  &.block {
    padding-bottom: 5px;
  }

  .user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    .avatar {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }

    .name {
      font-size: 14px;
      color: #fff;
      font-weight: bold;
      flex: 1;
      margin-left: 5px;
    }

    .living {
      color: red;
      font-size: 12px;
    }
  }

  .stats {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    color: #fff;
    font-size: 12px;
    flex-wrap: wrap;
    .stat {
      // margin: 0 15px 15px 0;
      margin-bottom: 15px;
      margin-right: 15px;
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
      width: calc((100% - 30px) / 3);
    }

    .value {
      font-size: 16px;
      margin-top: 5px;
      font-size: 14px;
    }

    .stat {
      text-align: center;
    }
  }
}
.head {
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 14px;
    color: #333;
    font-weight: bold;
  }
}
</style>